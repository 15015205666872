
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


.slider {
	background: transparent;
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 960px;
}


.slide-track {
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(200px * 14);
}

.slide {
	height: 100px;
	width: 200px;
}