html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  height: 100%;
}

.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.highlight-3 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=ffffff);
  margin: -2px -6px;
  padding:  2px  6px;
}

.onboarding-logo{
  width: 5em;
  height: 3em;
}

.user-bio {
  margin: 0.5em 0em;
}

.img-circle {
  display: flex;
  justify-content: center;
}

.service.theme .link {
  margin-bottom: 16px;
  padding: 0;
  position: relative;
}

.links {
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  margin: 0.1em 0em;
}

.wave-container > svg {
  display: block;
}

.wave-container {
  position: absolute;
  width: 100%;
  background: transparent;
  color: #FFF;
  text-align: center;
  overflow: hidden;
  bottom: 0;
}

::-webkit-scrollbar       {background-color:#fff;width:16px}
::-webkit-scrollbar-track {background-color:#fff}
::-webkit-scrollbar-thumb {background-color:#babac0;border-radius:16px;border:4px solid #fff}


@keyframes animateWave {
  0% {
    transform: scale(1,0);
  }
  100% {
    transform: scale(1,1);
  }
}
.wave-container > svg {
  display: block;
  transform-origin: bottom;
  animation: animateWave 3000ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.logo {
  background-image: url(https://camo.githubusercontent.com/d0ea07a95a7dc161f5a84ef1aee7724905fe1b3d/68747470733a2f2f63646e2e737667706f726e2e636f6d2f6c6f676f732f626974726973652e737667);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 150px;
  height: 20px;
}

.logo-dashboard-container{
  display: flex;
  justify-content: center;
}

.dashboard-logo {
  width: 10em;
  height: 2em;
}

.home-logo {
  width: 10em;
  height: 2em;
}

.dashboard-profile-image {
  width: 2em;
  height: 2em;
  border-radius: 2em;
}

.AutoSizerWrapper {
  width: 24em;
  height: 17em;
}

.button-toggle-switch {
  transition: all 0.3s ease-in-out;
}

input:checked ~ .button-toggle-switch {
  transform: translateX(100%);
  background-color: #48bb78;
}

#user_rates_tabs button {
  background: transparent;
  color: rgb(8, 145, 178);
  &[aria-selected='true'] {
    background: white;
  }
}

@media print {
  html, body {
    height:100%; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) {
  .AutoSizerWrapper {
    width: 17em;
    height: 17em;
  }
  
}

@media only screen and (min-width : 375px) {
  .AutoSizerWrapper {
    width: 19em;
    height: 17em;
  }
}

@media only screen and (min-width : 425px) {
  .AutoSizerWrapper {
    width: 22em;
    height: 17em;
  }
}

@media only screen and (min-width : 768px) {
  .AutoSizerWrapper {
    width: 24em;
    height: 17em;
}
}

@media only screen and (min-width : 1024px) {

}
