@tailwind base;
@tailwind components;
@tailwind utilities;

.stage {
    /* sets the background of the scene */
    --c-bg: hsl(262,39%,20%);
    
    align-items: center;
    background-color: var(--c-bg);
    display: flex;
    height: 100%;
    justify-content: center;
    overflow-y: hidden;
    width: 100%;
  }
  
  .scene {
      height: 500px;
      position: relative;
      width: 400px;
  }
  
  @keyframes scene-anim {
      0% {
          box-shadow: inset 0 0 0 3px var(--c-glow);
        /* box-shadow: out/inset x-ofset y-ofset blur size color */
      }
      20% {
          box-shadow: inset 0 0 0 8px var(--c-glow);
      }
      40% {
          box-shadow: inset 0 0 0 2px var(--c-glow);
      }
      60% {
          box-shadow: inset 0 0 0 6px var(--c-glow);
      }
      80% {
          box-shadow: inset 0 0 0 3px var(--c-glow);
      }
      100% {
          box-shadow: inset 0 0 0 5px var(--c-glow);
      }
  }
  
  /* Sets the SASS variables relating to anything with the halo class */
  .halo {
      --c-1: hsl(257,39%,27%);
      --c-2: hsl(259,38%,33%);
      --c-3: hsl(322,41%,43%);
      --c-4: hsl(346,86%,65%);
      --s-core: 100px;
      --s-1: 200px;
      --s-2: 140px;
      --s-3: 80px;
      --s-4: 20px;
  }
  
  /* Setting the css for everything with the halo or i class */
  .halo i {
      --c: var(--c-1);
      --s: var(--s-1);
  
      animation: 4s halo-anim 1.2s ease-in-out infinite;
      background-color: var(--c);
      box-shadow: 0 0 0 var(--s) var(--c);
      border-radius: 50%;
      display: block;
      left: calc(50% - (var(--s-core) / 2));
      height: var(--s-core);
      position: absolute;
      top: 50%;
      transform-origin: center center;
      width: var(--s-core);
  }
  
  
  /* Keyframes for amything with the halo class. Causes the slight breathing effect */
  @keyframes halo-anim {
      50% {
          transform: scale(1.05);
      }
  }
  
  /* Setting the colors and delay offset of the other helo circles*/
  .halo i:nth-of-type(2) {
      animation-delay: .9s;
      --c: var(--c-2);
      --s: var(--s-2);
  }
  .halo i:nth-of-type(3) {
      animation-delay: .6s;
      --c: var(--c-3);
      --s: var(--s-3);
  }
  .halo i:nth-of-type(4) {
      animation-delay: .3s;
      --c: var(--c-4);
      --s: var(--s-4);
  }